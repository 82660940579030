import * as React from 'react'

import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout'
import Seo from '../components/Seo';

const PrivacyPage = ({ data }) => {

  const { t } = useTranslation();

  return (
    <Layout >
      <Seo
        title={t("Privacy")}
      />
      <div className="max-w-md mx-auto rounded-xl md:max-w-2xl h-full flex">
        <div
          className="h-full"
        >
          <div
            className="p-6 mb-2 rounded-xl bg-grey-light dark:bg-grey-dark shadow-md text-text-light dark:text-text-dark"
          >
            <div dangerouslySetInnerHTML={{ __html: data.privacy.privacyPolicy }} />
          </div>
        </div>
      </div>
    </Layout >
  )
}

export default PrivacyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    privacy: iubendaDocument {
      privacyPolicy
    }
  }
`;